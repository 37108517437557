import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  cursor: default;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20vh;
  padding-bottom: 30vh;
`;

const StyledHeader = styled.h2`
  margin: 0px;
  font-size: 5vw;
  @media (max-width: 800px) {
    font-size: 10vw;
  }
`;

const AppTitle = styled.h1`
  letter-spacing: 3px;
  margin-top: 0;
  margin-bottom: 5vh;
  font-size: 7vw;
  @media (max-width: 800px) {
    font-size: 12vw;
  }
`;

const StyledDescription = styled.h4`
  font-size: 2.5vw;
  margin: auto;
  @media (max-width: 800px) {
    font-size: 6vw;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.light_grey};
  font-size: 35px;
  border: 2px solid ${({ theme }) => theme.light_grey};
  border-radius: 0px;
  padding: 20px;
  display: block;
  width: fit-content;
  margin: 50px auto 0 auto;
  :hover {
    color: ${({ theme }) => theme.green};
    border-color: ${({ theme }) => theme.green};
  }
  @media (max-width: 400px) {
    width: 60vw;
    font-size: 9vw;
  }
`;

class Home extends Component {
  state = {
    listId: this.uuidv4(),
  };

  // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  render() {
    return (
      <StyledWrapper>
        <div>
          <StyledHeader>Welcome to</StyledHeader>
          <AppTitle>Live TODO!</AppTitle>
          <StyledDescription>
            Click the button to create a new list <br /> 
            then share with friends
          </StyledDescription>
        </div>
        <StyledLink to={`/list/${this.state.listId}`}>
          Start new list
        </StyledLink>
      </StyledWrapper>
    );
  }
}

export default withTheme(Home);
