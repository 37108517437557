import React from "react";
import styled from "styled-components";
import { Add } from "@styled-icons/ionicons-outline/Add";

const StyledInputWrapper = styled.div`
  margin-top: 25px;
  padding-left: 5px;
  position: relative;
`;

const StyledNewItemInput = styled.textarea`
  display: inline-block;
  width: calc(100% - 40px);
  color: ${({ theme }) => theme.light_grey};
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-top: 10px;
  padding-bottom: 3px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  :focus {
    outline: none;
  }

  &:valid ~ label,
  &:focus ~ label {
    top: -20px;
    font-size: ${({ theme }) => theme.fontSize.xxs};
  
  }
`;

const StyledLabel = styled.label`
  padding-left: 10px;
  position: absolute;
  top: 5px;
  left: 2px;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: normal;
  pointer-events: none;
  transition: 0.2s ease all;
`;

const StyledAddButton = styled(Add)`
  float: right;
  width: 30px;
  color: ${({ theme }) => theme.light_grey};
  height: 30px;
  position: relative;
  top: 7px;
  margin-left: 3px;
  margin-right: 7px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const NewItemInput = ({ type, value, handleKeyPress, onClick, onChange }) => (
  <StyledInputWrapper>
    <StyledNewItemInput
      type={type}
      value={value}
      onKeyPress={handleKeyPress}
      onChange={onChange}
      required
    />
    <StyledLabel>Add item</StyledLabel>
    <StyledAddButton onClick={onClick} />
    <div style={{ clear: "both" }} />
  </StyledInputWrapper>
);

export default NewItemInput;
