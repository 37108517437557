import React from "react";
import styled from "styled-components";
import { Link } from "@styled-icons/feather/Link";
import { Checkbox } from "@styled-icons/ionicons-outline/Checkbox";
import { Square } from "@styled-icons/ionicons-outline/Square";
import { Trash2 } from "@styled-icons/feather/Trash2";

const StyledFieldset = styled.fieldset`
  border: 2px solid ${({ theme }) => theme.grey};
  color: ${({ theme }) => theme.grey};
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
`;

const MenuWrapper = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.grey};
  width: 100%;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  display: flex;
  padding-top: 0;
  padding: 10px;
`;

const StyledLinkInput = styled.input`
  width: 95%;
  direction: rtl;
  text-overflow: ellipsis;
  background-color: transparent;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border: none;
  //border-bottom: 1px solid ${({ theme }) => theme.grey};
  //position: relative;
  //bottom: 21px;
  display: none; 
  :focus {
    display: block;
    outline: none;
  }
  font-size: 1.2rem;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  text-align: center;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  * {
    color: ${({ theme }) => theme.light_grey};
  }

  :hover * {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledCopyButton = styled(Link)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const StyledCheckboxButton = styled(Checkbox)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const StyledSquareButton = styled(Square)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const StyledRemoveoButton = styled(Trash2)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const StyledButtonText = styled.p`
  font-size: 13px;
  margin: 0;
  cursor: pointer;
  line-height: 1;
`;

const Menu = ({
  inputReference,
  type,
  value,
  onCopyButtonClick,
  checkAll,
  uncheckAll,
  allChecked,
  removeChecked,
  buttonColor,
}) => {
  return (
    <MenuWrapper>
      <StyledLinkInput
        readOnly
        onFocus={(e) => e.target.select()}
        ref={inputReference}
        type={type}
        value={value}
      />

      {
        !allChecked
        && (
          <ButtonWrapper style={{ color: buttonColor }} onClick={checkAll}>
            <StyledCheckboxButton />
            <StyledButtonText>
              Check all
            </StyledButtonText>
          </ButtonWrapper>
        )
      }
      {
        allChecked && (
          <ButtonWrapper style={{ color: buttonColor }} onClick={uncheckAll}>
            <StyledSquareButton />
            <StyledButtonText>
              Uncheck all
            </StyledButtonText>
          </ButtonWrapper>
        )
      }

      <ButtonWrapper style={{ color: buttonColor }} onClick={removeChecked}>
        <StyledRemoveoButton />
        <StyledButtonText>
          Remove checked
        </StyledButtonText>
      </ButtonWrapper>

      <ButtonWrapper style={{ color: buttonColor }} onClick={onCopyButtonClick}>
        <StyledCopyButton />
        <StyledButtonText>
          Copy link
        </StyledButtonText>
      </ButtonWrapper>
    </MenuWrapper>
  );
};

export default Menu;
