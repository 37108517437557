import React from "react";
import Home from "./views/Home";
import List from "./views/List";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import customHistory from "./helpers/history";
import MainTemplate from "./templates/MainTemplate";

const App = () => (
  <Router history={customHistory}>
    <MainTemplate>
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path={"/list/:id"} component={List} />
        <Route path='*' render={() => <Redirect to="/" />} />
      </Switch>
    </MainTemplate>
  </Router>
);

export default App;
