import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
body,
#root {
    background-color: #262626;
    min-height: 100vh;
}

html,
body,
#root,
input, select, textarea,
a, a:visited, a:link {
    color: #E0E0E0;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
}

body {
  margin: 0 0;
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea:not([rows]) {
  min-height: unset;
}

`;

export default GlobalStyle;
