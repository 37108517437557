export const theme = {
  green: "rgb(61, 156, 20)",
  black: "rgb(0, 0, 0)",
  red: "rgb(199, 54, 44)",
  white: "rgb(255,255,255)",
  light_grey: "#D0D0D0",
  grey: "rgb(117, 122, 111)",
  dark_grey: "#181818",

  light: 300,
  bold: 600,
  fontSize: {
    xxs: "0.9rem",
    xs: "1.7rem",
    s: "1.9rem",
    m: "2rem",
    l: "3rem",
    xl: "5rem",
  },
};
