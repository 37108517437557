import React, { Component } from "react";
import styled, { withTheme, css } from "styled-components";
import Menu from "../components/Menu";
import NewItemInput from "../components/NewItemInput";
import ListItem from "../components/ListItem";
import { WsClient } from "../helpers/WsClient";
import RestorePopup from "../components/RestorePopup";
import Sortable from "sortablejs";

const StyledWrapper = styled.div`
  margin-left: auto;
  position: relative;
  margin-right: auto;
  padding-top: 15px;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    padding-top: 60px;
    width: 600px;
  }
`;

const PopupsContainer = styled.div`
  width: 580px;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 10px;
  top: 10px;
  z-index: 50;
  @media (max-width: 768px) {
    width: 98%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ListItems = styled.div`
  width: 100%;
`;

class List extends Component {
  listId = this.props.match.params.id;
  state = {
    list: this.getListFromLocalStorage(),
    itemName: "",
    recentlyDeletedItems: [],
    buttonColor: this.props.theme.grey,
  };

  debounce(func, timeout = 1500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  processSendingUpdateMessage = this.debounce(
    async () => await this.connection.sendUpdateMessage(this.state.list), 500
  );

  processUpdatingLocalStorage = () => this.updateLocalStorage();

  handleOrderChange = (newIndex, item) => {
    this.setState(
      (oldState) => {
        let it = oldState.list.find((i) => i.id === item.id);
        if (!it) return oldState;
        let list = oldState.list
          .filter((i) => i !== it)
          .sort((a, b) => a.done - b.done || a.deleted - b.deleted);
        list.splice(newIndex, 0, it);

        return { ...oldState, list };
      },
      () => {
        this.processSendingUpdateMessage();
        this.processUpdatingLocalStorage();
      }
    );
  };

  updateLocalStorage() {
    localStorage.setItem(
      `list/${this.listId}`,
      JSON.stringify(this.state.list)
    );
  }

  async subscribe() {
    // Copied from the web-push documentation
    const urlBase64ToUint8Array = (base64String) => {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    };
    const notificationsServerPublicKey =
      "BIrozx3wZy_Aw5BeV-ZkX54-G5IS9Q6cm0dI9bjw6xjqlTyPwK9RkCfbTdGrcSJZxGsr8H7A3vAx3mozfkZgrDY";
    console.log("Requesting notification permission...");
    const sw = await navigator.serviceWorker.ready;
    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(notificationsServerPublicKey),
    });

    await this.connection.sendSubscribeNotificationMessage(subscription);
  }

  componentDidMount() {
    var el = document.getElementById("listItems");
    const handleOrderChange = this.handleOrderChange;

    var sortable = Sortable.create(el, {
      chosenClass: "chosenListItem",
      dragClass: "sortable-drag",
      ghostClass: "sortable-ghost",
      animation: 50,
      delay: 300,
      delayOnTouchOnly: true, // only delay if user is using touch
      draggable: ".unchecked",
      onEnd: function(evt) {
        handleOrderChange(evt.newIndex, evt.item);
      },
    });

    this.connection = new WsClient({
      listId: this.listId,
      onUpdate: (listFromMessage) =>
        this.setState({ list: listFromMessage }, () => {
          this.processUpdatingLocalStorage();
          this.subscribe();
        }
        ),
    });
  }

  // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function(c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getListFromLocalStorage() {
    let list = localStorage.getItem(`list/${this.listId}`);
    return list ? JSON.parse(list) : [];
  }

  addItem() {
    if (this.state && this.state.itemName !== "") {
      this.setState(
        (oldState) => ({
          list: [
            ...oldState.list,
            {
              id: this.uuidv4(),
              title: this.state.itemName,
              done: false,
              deleted: false,
              updated: new Date().getTime(),
            },
          ],
        }),
        () => {
          this.processSendingUpdateMessage();
          this.processUpdatingLocalStorage();
        }
      );
    }
    this.setState({ itemName: "" });
  }

  removeItem(item) {
    this.setState(
      (oldState) => ({
        list: [
          ...oldState.list.map((x) =>
            x.id === item.id
              ? { ...x, deleted: true, updated: new Date().getTime() }
              : x
          ),
        ],
        recentlyDeletedItems: [item, ...oldState.recentlyDeletedItems],
      }),
      () => {
        this.processSendingUpdateMessage();
        this.processUpdatingLocalStorage();
        setTimeout(() => {
          const popup = document.getElementById("popup" + item.id);
          if (popup) popup.style.opacity = "0";
          setTimeout(() => {
            this.removeItemFromRecentlyDeletedList(item);
          }, 500);
        }, 2000);
      }
    );
  }

  removeItemFromRecentlyDeletedList(item) {
    this.setState((oldState) => ({
      recentlyDeletedItems: oldState.recentlyDeletedItems.filter(
        (i) => i !== item
      ),
    }));
  }

  restoreItem(item) {
    let restoredItem = ({
      ...item,
      updated: new Date().getTime()
    });
    this.setState(
      (oldState) => ({
        list: [...oldState.list, restoredItem],
      }),
      () => {
        this.removeItemFromRecentlyDeletedList(item);
        this.processSendingUpdateMessage();
        this.processUpdatingLocalStorage();
      }
    );
  }


  handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.addItem();
    }
  };

  updateElementInTheList(list, filter, factory) {
    return list.reduce((state, element) => {
      if (filter(element)) element = factory(element);
      return [...state, element];
    }, []);
  }

  checkAll = (done) => {
    setTimeout(() => {
      let item = this.state.list.find((i) => i.done != done && !i.deleted);
      if (item) {
        this.setChecked(item, done);
        this.checkAll(done);
      }
    }, 100);
  };

  setChecked = (item, checked) => {
    this.setState(
      (oldState) => {
        let list = this.updateElementInTheList(
          this.state.list,
          (element) => element.id === item.id && element.checked != checked,
          (element) => ({
            ...element,
            done: checked,
            updated: new Date().getTime(),
          })
        );
        return { ...oldState, list };
      },
      () => {
        this.processSendingUpdateMessage();
        this.processUpdatingLocalStorage();
      }
    );

  }

  handleCheckboxChange = (e, i) => {
    let checked = e.currentTarget.checked;
    this.setChecked(i, checked);
  };

  removeChecked = () => {
    setTimeout(() => {
      let item = this.state.list.find((i) => i.done && !i.deleted);
      if (item) {
        this.removeItem(item);
        this.removeChecked();
      }
    }, 100);

  }

  editItemTitle = (name, i) => {
    this.setState(
      (oldState) => {
        let list = this.updateElementInTheList(
          this.state.list,
          (element) => element.id === i.id,
          (element) => ({
            ...element,
            title: name,
            updated: new Date().getTime(),
          })
        );
        return { ...oldState, list };
      },
      () => {
        this.processUpdatingLocalStorage();
        this.processSendingUpdateMessage();
      }
    );
  };

  copyLinkToClipboard = () => {
    const link = this.Input;
    link.style.display = "block";
    link.select();
    document.execCommand("copy");
    link.blur();
    link.style.display = "none";
    this.setState({
      buttonColor: this.props.theme.grey,
    });

    setTimeout(
      () =>
        this.setState({
          buttonColor: this.props.theme.grey,
        }),
      2000
    );
  };

  render() {
    return (
      <div style={{ overflow: "auto" }}>
        <PopupsContainer>
          {this.state.recentlyDeletedItems.map((item, index) => (
            <RestorePopup
              key={index}
              id={"popup" + item.id}
              itemName={item.title}
              closePopup={() => this.removeItemFromRecentlyDeletedList(item)}
              onClick={() => this.restoreItem(item)}
            />
          ))}
        </PopupsContainer>
        <StyledWrapper>
          <Menu
            inputReference={(input) => (this.Input = input)}
            type="url"
            value={new URL(window.location.href)}
            onCopyButtonClick={() => this.copyLinkToClipboard()}
            checkAll={() => this.checkAll(true)}
            uncheckAll={() => this.checkAll(false)}
            removeChecked={() => this.removeChecked()}
            focusable="false"
            allChecked={this.state.list.some(i => !i.deleted) 
                        && this.state.list.every((i) => i.done || i.deleted)}
            buttonColor={this.state.buttonColor}
          />
          <NewItemInput
            onChange={(e) => this.setState({ itemName: e.target.value })}
            handleKeyPress={(e) => this.handleKeyPress(e)}
            onClick={() => this.addItem()}
            value={this.state.itemName}
            type="text"
          />
          <ListItems id="listItems">
            {this.state.list
              .filter((x) => !x.deleted)
              .sort((a, b) => a.done - b.done || a.deleted - b.deleted)
              .map((i) => (
                <ListItem
                  key={i.id}
                  type="checkbox"
                  id={i.id}
                  name={i.title}
                  checked={i.done}
                  title={i.title}
                  editItemTitle={(e) => this.editItemTitle(e, i)}
                  onCheckboxChange={(e) => {
                    this.handleCheckboxChange(e, i);
                  }}
                  onRemoveItemClick={() => this.removeItem(i)}
                />
              ))}
          </ListItems>
        </StyledWrapper>
      </div>
    );
  }
}

export default withTheme(List);
