import React from "react";
import styled from "styled-components";
import { X } from "@styled-icons/feather/X";
import { Restore } from "@styled-icons/material-twotone/Restore";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  padding: 0 15px;
  margin: 2px 0;
  border 2px solid ${({ theme }) => theme.grey};
  cursor: default;
  backdrop-filter: blur(7px);
  @media (min-width: 768px) {
    width: 580px;
  }
`;
const StyledDeletedText = styled.h5`
  font-size: 15px;
  margin: 2px 0;
  font-weight: 100;
  color: ${({ theme }) => theme.grey};
`;

const StyledTitle = styled.h3`
  margin: 0 10px 5px 0px;
  font-size: 30px;
  width: 100%;
  overflow: hidden;
  font-weight: 200;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 300px) {
    font-size: 7vw;
  }
`;

const LeftSection = styled.div`
  display: flex;
  width: calc(100% - 79px);
  flex-direction: column;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledRestoreButton = styled(Restore)`
  cursor: pointer;
  margin: 0 3px;
  color: ${({ theme }) => theme.light_grey};

  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const RestorePopup = ({ id, itemName, onClick, closePopup }) => (
  <StyledContainer key={id} id={id}>
    <LeftSection>
      <StyledDeletedText>Deleted</StyledDeletedText>
      <StyledTitle>{itemName}</StyledTitle>
    </LeftSection>
    <RightSection>
      <StyledRestoreButton onClick={onClick} size="30" />
    </RightSection>
  </StyledContainer>
);

export default RestorePopup;
