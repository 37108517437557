const createWsClient = ({ listId, onUpdate }) => {
  const server_endpoint = `${window.location.protocol.replace("http", "ws")}//${
    // window.location.hostname
    window.location.hostname.replace("localhost", "localhost:8000")
    // "localhost:8080"
    // "livetodo.flisikowska.com"
  }/list/${listId}`;
  let ws = new WebSocket(server_endpoint);

  ws.onmessage = (e) => {
    const message = JSON.parse(e.data);
    onUpdate(message.list);
  };

  ws.onopen = () => {
    console.log("connected websocket component");
  };

  ws.onerror = (err) => {
    console.error("Socket encountered error: ", err, "Closing socket");
    ws.close();
  };

  return ws;
};

export class WsClient {
  constructor({ listId, onUpdate }) {
    this.listId = listId;
    const check = () => {
      if (
        !this.ws ||
        this.ws.readyState == WebSocket.CLOSED ||
        this.ws.readyState == WebSocket.CLOSING
      )
        this.ws = createWsClient({ listId, onUpdate });
    };

    this.checkInterval = setInterval(check, 500);
    check();
  }

  async sendUpdateMessage(list) {
    while (this.ws.readyState !== WebSocket.OPEN)
      await new Promise((resolve) => setTimeout(resolve, 2000));

    const message = JSON.stringify({ type: "Update", list: list });
    this.ws.send(message);
    return;
  }

  async sendSubscribeNotificationMessage(subscription) {
    while (this.ws.readyState !== WebSocket.OPEN)
      await new Promise((resolve) => setTimeout(resolve, 2000));

    const message = JSON.stringify({
      type: "Subscription",
      subscription,
    });

    this.ws.send(message);
    return;
  }
}
