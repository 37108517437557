import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Remove } from "@styled-icons/ionicons-sharp/Remove";
import { Close } from "@styled-icons/ionicons-sharp/Close";
import { Checkmark } from "@styled-icons/ionicons-outline/Checkmark";
import { Square } from "@styled-icons/ionicons-outline/Square";
import { Checkbox } from "@styled-icons/ionicons-outline/Checkbox";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

const ItemWrapper = styled.div`
  display: flex;
  width: calc(100% - 4px);
  ${(props) =>
    props.editMode &&
    css`
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
      border 2px dashed ${({ theme }) => theme.grey};
    `}
  margin: 6px 2px;
  padding: 8px 0;
  font-size: ${({ theme }) => theme.fontSize.s};
  :hover {
    background-color: ${({ theme }) => theme.dark_grey};
    //border 2px dashed ${({ theme }) => theme.green};
    //border-radius: 5px;
    //border 2px dashed ${({ theme }) => theme.grey};
  }
  &.chosenListItem {
    background-color: ${({ theme }) => theme.dark_grey};
    //border 2px dashed ${({ theme }) => theme.grey};
    //border-radius: 5px;
    //background: radial-gradient(
    //  circle,
    //  rgba(234, 235, 233, 1) 0%,
    //  rgba(209, 227, 224, 1) 100%
    //);
  }
`;

const HiddenCheckbox = styled.input`
  -webkit-appearance: none;
  float: left;
`;

const StyledUncheckedBox = styled(Square)`
  margin-left: 7px;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  position: relative;
  bottom: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledCheckedBox = styled(Checkbox)`
  margin-left: 7px;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  position: relative;
  bottom: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledItemTitle = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.m};
  position: relative;
  top: 2px;
  user-select: none;
  padding: 0 10px;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const EditItemTitle = styled.textarea`
  width: 100%;
  overflow: hidden;
  padding: 0 5px;
  color: ${({ theme }) => theme.light_grey};
  font-size: ${({ theme }) => theme.fontSize.m};
  position: relative;
  min-height: 50px;
  top: 2px;
  background-color: transparent;
  border: none;
  overflow: hidden;
  overflow-wrap: break-word;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  //border 2px dashed ${({ theme }) => theme.grey};
  resize: none;
  :focus {
    outline-width: 0 !important;
    outline: none !important;
  }
  ${(props) =>
    props.errorMode &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.red};
    `}

    :not([rows]) {
      min-height: 1em;
    }
`;

const StyledSaveButton = styled(Checkmark)`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  margin: 10px 6px 0 auto;
  color: rgba(150, 150, 150, 1);
  cursor: pointer;
  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledEditButton = styled(EditOutline)`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  margin: 10px 4px 0 auto;
  cursor: pointer;
  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledDeleteButton = styled(Remove)`
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  margin: 8px 5px 0 0;
  cursor: pointer;

  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const StyledDiscardChangesButton = styled(Close)`
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  margin: 8px 5px 0 0;
  cursor: pointer;

  color: ${({ theme }) => theme.light_grey};
  :hover {
    color: ${({ theme }) => theme.green};
  }
`;

const ListItem = ({
  type,
  id,
  name,
  title,
  checked,
  onCheckboxChange,
  onRemoveItemClick,
  focusable,
  editItemTitle,
}) => {
  const [itemTitle, setItemTitle] = useState(title);
  const [editMode, setEditMode] = useState(false);
  const [errorMode, setErrorMode] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (itemTitle.trim().length === 0) {
        setErrorMode(true);
      } else {
        editItemTitle(itemTitle);
        setEditMode(false);
      }
    }
  };

  return (
    <ItemWrapper
      editMode={editMode}
      className={checked ? "checked" : "unchecked"}
      id={id}
      key={id}
    >
      <label style={{ width: "37px" }}>
        <HiddenCheckbox
          type={type}
          id={id}
          name={name}
          checked={checked}
          onChange={onCheckboxChange}
          focusable={focusable}
        />
        {checked ? <StyledCheckedBox /> : <StyledUncheckedBox />}
      </label>
      {editMode ? (
        <>
          <EditItemTitle
            errorMode={errorMode}
            autoFocus
            onFocus={(e) => {
              const element = e.target;
              element.setSelectionRange(
                element.value.length,
                element.value.length
              );
              element.style.height = "";
              element.style.height = element.scrollHeight + "px";
            }}
            onChange={(e) => {
              setErrorMode(false);
              setItemTitle(e.target.value);
            }}
            onInput={(e) => {
              const element = e.target;
              element.style.height = "";
              element.style.height = element.scrollHeight + "px";
            }}
            defaultValue={itemTitle}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <StyledSaveButton
            onClick={() => {
              if (itemTitle.trim().length !== 0) {
                editItemTitle(itemTitle);
                setEditMode(false);
              } else {
                setErrorMode(true);
              }
            }}
          />

          <StyledDiscardChangesButton onClick={() => {
            setEditMode(false);
          }} />
        </>
      ) : (
        <>
          <StyledItemTitle htmlFor="checkbox">{title}</StyledItemTitle>
          <StyledEditButton
            onClick={() => {
              setEditMode((x) => !x);
            }}
          />
          <StyledDeleteButton onClick={onRemoveItemClick} />
        </>
      )}
    </ItemWrapper>
  );
};

export default ListItem;
